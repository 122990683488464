import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login'
import Hello from '@/components/HelloWorld'
import Valet from '@/components/Valet'

// ... other imports ...
import axios from '@/backend'

Vue.use(Router)

export const router = new Router({
  routes: [
    {
      path: '/authors',
      name: 'Authors',
      component: Hello
    },
    {
      path: '/',
      name: 'Login',
      component: Login
    },
    {
      path: '/active',
      name: 'Valet',
      component: Valet,
      props: true, // necessary to send zone_id
      beforeEnter: (to, from, next) => {
        if (from.name !== 'Login') next('Login')
        next()
      }
    },
    { path: '*', redirect: '/' }
  ]
})

router.beforeEach((to, from, next) => {
  const publicPaths = ['/']
  const authRequired = !publicPaths.includes(to.path)
  const loggedIn = !!localStorage.token
  if (!authRequired) next()
  if (!loggedIn) {
    console.log('auth required and we are not logged in')
    return next(false)
  }

  // At this point, we know that the path requires auth and
  // there's a token. We need to validate the token with the
  // backend before proceeding. (It could be old)

  const token = localStorage.token

  // save token in axios headers also for easy API usage
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

  console.log('--> token validation to server')
  axios.get('/auth-check')
    .then((request) => {
      if (!request.data.success) {
        throw new Error()
      }
      // good token, so let's proceed. At this point we should allow
      // for an automatic token refresh while the user is still on
      // the site. <-- TODO
      next()
    })
    .catch(() => {
      console.log('unauthorized access')
      console.log('removing token: ', localStorage.token)
      // window.localStorage.removeItem('token')
      delete localStorage.token
      delete axios.defaults.headers.common['Authorization']
      next(Login)
    })
})
