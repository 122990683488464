<template>
    <div >
        <div class="modal is-active">
        <div @click="stopcapture(); $emit('close')" class="modal-background"></div>
        <div class="modal-card">
            <section class="modal-card-body has-text-centered">
                <p> {{ tapMessage }} </p>
                <div class="my-video" v-on:click="capture()">
                    <video ref="video" id="video" autoplay muted playsinline></video>
                    <div v-if="make" class="has-text-weight-bold is-size-7 my-overlay">
                        <p v-if="make">Make: {{ make }}, Model: {{ model }}, Accuracy: {{ prob | formatProb }}%</p>
                        <p v-if="plate">Plate: {{ plate }}, Color: {{ color }}, Angle: {{ angle }} </p>
                        <p v-else> Plate not recognized </p>
                    </div>
                    <div v-if="!found" class="has-text-weight-bold is-size-7 my-overlay">
                      <p> Vehicle not recognized. Please try again. </p>
                </div>
                </div>
                <canvas width="1280" height="720" ref="canvas" id="canvas"></canvas>
            </section>
            <div class="thumbnails">
                <li v-for="(c, indc) in captures" :key="indc">
                    <img v-bind:src="c" width="80"/>
                </li>
            </div>

            <footer class="modal-card-foot" style="justify-content: space-between">
                <div class="is-grouped">
                    <button v-if="make" @click="stopcapture(); $emit('scan', {'plate': plate, 'color': color, 'make': make, 'model': model}); $emit('close')" class="button is-info is-medium">{{ make }} {{ model }}</button>
                    <button @click="stopcapture(); $emit('close')" class="button is-outline is-medium">Cancel</button>
                </div>
                <span v-if="make && plate && color" class="icon is-medium" v-bind:class="accuracyMeter()" >
                    <i class="fas fa-signal fa-2x"></i>
                </span>
            </footer>
        </div>
        </div>
    </div>
</template>

<script>
import axios from '../backend';

export default {
  name: 'ValetScan',
  props: [],
  data() {
    return {
      tapMessage: 'Tap the image to scan vehicle', // message changes when processing
      video: {}, // video object from html
      canvas: {}, // canvas object, where to draw
      captures: [], // array of captures
      make: '',
      model: '',
      prob: '',
      bbox: {}, // bounding box for car
      color: '',
      plate: '',
      found: true,
      angle: '',
    };
  },

  /* turn confidence prob in to a percentage */
  filters: {
    formatProb(value) {
      return Math.floor(value);
    },
  },

  /* when DOM mounted, check to make sure that getUserMedia is supported */
  mounted() {
    console.log('will try camera now');
    const supported = 'mediaDevices' in navigator;
    console.log(`Supported? ${supported}`);

    // https://webrtchacks.com/guide-to-safari-webrtc/ << -- this is the ONLY good source of info
    const constraints = {
      video: true,
    };
    navigator.mediaDevices.getUserMedia(constraints)
      .then(this.handleSuccess).catch((error) => {
        console.error('getUserMedia() error: ', error);
      });
  },
  methods: {
    accuracyMeter() {
      const pre = 'has-text-';
      console.log(`this is prob: ${this.prob}`);
      if (this.prob > 80) return `${pre}success`;
      if (this.prob > 50) return `${pre}warning`;
      return `${pre}danger`;
    },

    /* if getUserMedia supported, enumerate device and turn on video */
    handleSuccess(stream) {
      stream.getVideoTracks().forEach((el) => {
        el.stop();
      });
      navigator.mediaDevices.enumerateDevices()
        .then(this.getDeviceId)
        .catch((error) => {
          console.error('enumerateDevices() error: ', error);
          const config = {
            method: 'post',
            url: 'https://protected-spire-79719.herokuapp.com/99/errors',
            data: error,
            headers: { 'Content-Type': 'application/json' },
          };
          axios(config);
        });
    },

    /* get the environment/back camera instantiated. make sure mobile is handled
           play the video after setting up constraints. THIS IS THE MAIN FUNCTION! */
    getDeviceId(devices) {
      // log the devices found
      const config = {
        method: 'post',
        url: 'https://protected-spire-79719.herokuapp.com/99/devices',
        data: devices,
        headers: { 'Content-Type': 'application/json' },
      };
      axios(config);

      let videoDeviceId;

      // for each device found, find the back camera
      for (let i = 0; i < devices.length; ++i) {
        const device = devices[i];
        console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
        if (device.kind === 'videoinput') {
          if (device.label.toLowerCase().includes('back')) {
            videoDeviceId = device.deviceId;
            break;
          } else if (!device.label.toLowerCase().includes('front')) {
            videoDeviceId = device.deviceId;
            break;
          }
        }
      }

      // Sensible default.
      if (!videoDeviceId) {
        videoDeviceId = devices[0].deviceId;
      }

      console.log(videoDeviceId);

      let isMobile = false; // initiate as false
      // device detection
      // https://stackoverflow.com/questions/3514784/what-is-the-best-way-to-detect-a-mobile-device
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
                || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
        isMobile = true;
      }
      console.log(`isMobile: ${isMobile}`);

      // Now, the discovered deviceId can be used in getUserMedia() requests.
      const constraints = {
        audio: false,
        video: {
          deviceId: {
            exact: videoDeviceId,
          },
          height: {
            ideal: (isMobile) ? 1280 : 720,
          },
          width: {
            ideal: (isMobile) ? 720 : 1280,
          },
          facingMode: {
            ideal: 'environment',
          },
        },
      };

      // rutn on the stream based on the above (discovered) constraints
      this.video = this.$refs.video;
      navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
          console.log(stream);
          this.video.srcObject = stream;
          this.video.play();

          // publish stats on what the browser ultimately did
          const stats = {
            deviceId: stream.getVideoTracks()[0].getSettings().deviceId,
            frameRate: stream.getVideoTracks()[0].getSettings().frameRate,
            height: stream.getVideoTracks()[0].getSettings().height,
            width: stream.getVideoTracks()[0].getSettings().width,
            isMobile,
            userAgent: navigator.userAgent,
          };
          console.log(JSON.stringify(stats, 0, 2));
          const config = {
            method: 'post',
            url: 'https://protected-spire-79719.herokuapp.com/99/stats',
            data: stats,
            headers: { 'Content-Type': 'application/json' },
          };
          axios(config);
        })
        .catch((error) => {
          // dump errors to heroku for analysis if getUserMedia fails with those constraints
          console.error('getUserMedia() error: ', error);
          const config = {
            method: 'post',
            url: 'https://protected-spire-79719.herokuapp.com/99/getUserMediaERR',
            data: error,
            headers: { 'Content-Type': 'application/json' },
          };
          axios(config);
        });
    },

    /* draw a bounding box on the canvas around the car */
    drawBB(bbox) {
      const ctx = this.canvas.getContext('2d');
      ctx.beginPath();
      ctx.lineWidth = '12';
      ctx.strokeStyle = 'red';
      ctx.rect(this.canvas.width * bbox.tl_x,
        this.canvas.height * bbox.tl_y,
        this.canvas.width * (bbox.br_x - bbox.tl_x),
        this.canvas.height * (bbox.br_y - bbox.tl_y));
      ctx.stroke();
    },

    /* if image is tapped, capture an image and push to the recognition API */
    capture() {
      this.canvas = this.$refs.canvas;
      this.canvas.getContext('2d').drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
      // var ctx = this.canvas.getContext('2d')

      this.plate = this.color = this.make = this.model = this.prob = this.angle = '';
      this.found = true;

      // toBlob is good. it keeps it as a buffer of bytes, no base64 mumbo-jumbo
      // console.log(canvas.toDataURL('image/png'))
      canvas.toBlob((blob) => {
        this.video.pause(); // pause while processing
        const oldTapMessage = this.tapMessage;
        this.tapMessage = 'PROCESSING IMAGE ...';
        const caridConfig = {
          method: 'post',
          // url: 'http://localhost:5000/3/carid',
          url: 'https://api.park.i.ng/3/carid',
          data: blob,
          headers: { 'Content-Type': 'image/jpeg' },
        };
        axios(caridConfig)
          .then((res) => {
            this.video.play(); // un-pause once recognition is complete
            this.tapMessage = oldTapMessage;
            const { carnet, oa, pr } = res.data;
            if (carnet) {
              /* Carnet does some funny stuff:
               * (1) if it detects a car, but can't recognize it, it will still come back and tell you as much {subclass}
               * (2) otherwise it may not come back at all, if it doesn't even detect a car
               * (3) if it detects and recognizes, then you have something. So I've overused "this.found". 
               */
              console.log({carnet})
              this.drawBB(carnet.box);
              if (carnet.status) {
                this.color = 'name' in carnet.color ? carnet.color.name : '';
                this.make = 'make_name' in carnet.mmg ? carnet.mmg.make_name.toLowerCase() : ''
                this.model = 'model_name' in carnet.mmg ? carnet.mmg.model_name.toLowerCase() : ''
                this.prob = 'prob' in carnet.mmg ? carnet.mmg.prob * 100 : ''
                this.angle = 'name' in carnet.angle ? carnet.angle.name : ''
              } else {
                this.found = false;
              }
            }
            if (oa) {
              console.log({oa})
              this.plate = oa.plate.toUpperCase();
              this.color = oa.color;
              this.make = oa.make;
              this.model = oa.model || '';
              this.prob = oa.confidence_pl_co_mamo[2];
            } 
            if (pr) {
              console.log({pr})
              this.plate = pr.plate.toUpperCase();
              this.prob = pr.confidence * 100;
            }
            if ( (typeof carnet === 'undefined' || !this.found) && (typeof oa === 'undefined') && (typeof pr === 'undefined') ) {
              console.log('vehicle not identified');
              this.found = false;
              throw new Error('no vehicles detected');
            }
            this.captures.push(canvas.toDataURL('image/png'));
          })
          .catch(err => console.log(`ERROR: ${err.message}`));
      }, 'image/png');
    },

    /* when user cancels or closes window, stop the video */
    stopcapture() {
      this.video.srcObject.getVideoTracks().forEach((el) => {
        el.stop();
      });
    },
  },
};

</script>

<style scoped>
    .thumbnails {
        background-color: white;
    }
    .my-video {
        position: relative;
        border: 3px solid #000;
        /* border-image: url('https://i.stack.imgur.com/1WlsT.png') 34% repeat; */
        width: 90vw;
        height: 51vw;
        background-color: black;
    }

    .my-overlay {
        position: absolute;
        bottom: 0;
        width: 89vw;
        height: 3em;
        z-index: 50;
        background-color: rgba(255,255,255,0.8);
        color: black;
    }

    #video {
        background-color: #000;
        /* border: 1px solid #19c003; */

    }
    #canvas {
        display: none;
    }
    li {
        display: inline;
        padding: 2px;
    }
</style>
