<template>
  <div style="margin-bottom: 100px">
    <div id="checkin" class="">
      <p class="title has-text-centered">{{zoneName}}</p>
          <!-- <div class="hero-body"> -->
          <!-- <div class="container"> -->
      <!-- <h1 class="title has-text-centered is-size-2">VALET CHECK-IN</h1>   -->
      <div class="box" style="border: 1px solid lightgrey">

        <!-- our signup form ===================== -->
        <form id="signup-form" @submit.prevent="processForm">

          <!-- number plate -->
          <div class="field is-grouped">
            <div class="control is-expanded">
            <input
              type="text"
              class="input is-medium"
              name="plate"
              v-model="plate"
              @blur="validatePlate"
              v-on:keyup="plate = plate.toUpperCase()"
              placeholder="Plate">
            </div>

            <div class="control" v-if="enhCheckin">
              <div @click="showScan = true" class="button is-link is-medium">
                <i class="fas fa-car"></i>
              </div>
            </div>
          </div>

          <p class="help is-danger" v-if="errors.plate">
            Please enter at least 6 characters.
          </p>

          <!-- phone -->
          <!-- <label class="label">Mobile</label> -->
          <div class="field has-addons">

            <div class="control">
              <span class="select is-medium">
                <select class="has-text-grey-light" v-model="cc">
                  <option v-for="(code, ccidx) in ccList" :key="ccidx">{{ code }} </option>
                  <!-- <option>+1</option>
                  <option>+91</option>
                  <option>+44</option>
                  <option>+49</option>
                  <option>+61</option>
                  <option>+62</option>
                  <option>+65</option> -->
                </select>
              </span>
                <!-- <a class="button is-large has-text-grey-light">+91</a> -->
            </div>

            <!-- mobile number -->
            <div class="control is-expanded has-icons-right">
              <input
                type="tel"
                class="input is-medium"
                name="mobile"
                v-model="mobile"
                @blur="validateMobile"
                placeholder="Mobile">
              <span v-if="vip" class="icon is-right" style="padding-right:10px">
                <!-- <i class="fas fa-check"></i> -->
                <span class="tag is-warning">VIP</span>
              </span>
            </div>
          </div>
          <p class="help is-danger" v-if="errors.mobile">
            Please enter a valid number
          </p>

          <section v-if="enhCheckin">

            <hr class="my-hr" data-content="OPTIONAL">

            <div class="tags are-medium">
              <div v-for="(col, colidx) in colorList" :key="colidx" class="tag" style="border: 0.5px solid lightgrey; padding-right: 11px" v-bind:style="{ 'background-color': col }" v-on:click="otherColor = col" />
            </div>

            <div class="field">
              <span @keyup="onChangeColor"
                @mouseover="hoverC = true"
                @mouseleave="hoverC = false">
                <p class="control">
                  <input
                    type="text"
                    class="input is-medium"
                    name="otherColor"
                    autocomplete="off"
                    autocapitalize="off"
                    v-model="otherColor"
                    placeholder="Color">
                </p>
                <div class="menu is-medium" v-if="hoverC && (otherColor.length > 0)">
                  <ul class="menu-list has-background-white-ter my-menu">
                    <li v-for="(c, cidx) in completeColorList" :key="cidx" v-on:click="otherColor = c; hoverC = false"><a>{{ c }}</a></li>
                  </ul>
                </div>
              </span>
            </div>

            <div class="field is-grouped">
              <div class="control is-expanded"
                @keyup="onChangeMake"
                @mouseover="hoverM = true"
                @mouseleave="hoverM = false">

                <input
                  type="text"
                  class="input is-medium"
                  name="make"
                  autocomplete="off"
                  autocapitalize="off"
                  v-model="make"
                  placeholder="Make">

                <div class="menu is-medium" v-if="hoverM && (make.length > 0)">
                  <ul class="menu-list has-background-white-ter my-menu">
                    <li v-for="(m, midx) in makeList" :key="midx" v-on:click="make = m; ; hoverM = false"><a>{{ m }}</a></li>
                  </ul>
                </div>
              </div>
              <span class="icon has-text-black" style="margin-right: 10px; margin-top: 10px">
                <i class="is-size-3" v-bind:class="'car-' + make"></i>
              </span>
              <p  v-if=true class="control is-expanded">
                <input
                  type="text"
                  class="input is-medium"
                  name="model"
                  v-model="model"
                  placeholder="Model">
              </p>
            </div>

          </section>


          <!-- submit button -->
          <div class="field has-text-right" style="padding-top:20px">
            <button type="submit" class="button is-large is-fullwidth has-background-link" v-bind:class="submitcolor">
              {{ submittext }}
            </button>
          </div>

        <!-- <div class="is-pulled-right"> -->
          <a class="" v-on:click="resetForm()">Reset</a>
        <!-- </div> -->

        </form>
        <!-- footer -->
        <!-- <div class="myfooter ">
            - powered by <strong>DIVRT</strong> -
        </div> -->
      </div>

      <div class="notification has-text-centered" v-if="!summonDisable">
        <p>Summon requests will be sent via SMS to <u><strong>{{valetNumber}}</strong></u>. Tap  <i class="fas fa-cog"></i> above to change.</p>
      </div>
    </div>

    <!-- <vconsole :log="log"></vconsole> -->
    <valet-scan v-if="showScan" @close="showScan = false" @scan="scanresults"/>
  </div>
</template>

<script>
import ValetScan from './ValetScan';
import CarMakes from '../assets/car-makes.json';
import CompleteColorList from '../assets/color-list.json';

export default {
  name: 'ValetCheckin',
  props: ['zoneId', 'zoneName', 'valetNumber', 'enhCheckin', 'summonDisable'],
  components: {
    ValetScan,
  },
  data() {
    return {
      hoverM: false,
      hoverC: false,
      showScan: false,
      submitcolor: 'is-info',
      submittext: 'Check-in',
      plate: '',
      make: '',
      model: '',
      mobile: '',
      otherColor: '',
      cc: '+1',
      errors: {
        plate: false,
        mobile: false,
      },
      // checkoutCount: '',
      // checkinCount: '',
      // summonCount: '',
      // arriveCount: '',
      log: '',
      // zone_id: this.zoneId,
      ccList: [
        '+1',
        '+91',
        '+44',
        '+49',
        '+61',
        '+62',
        '+65',
      ],
      colorList: [
        'white',
        'black',
        'silver',
        'grey',
        'red',
        'blue',
        'tan',
        'lightblue',
        'darkgreen',
      ],
      makeList: [],
      completeColorList: [],
      modelList: [],
      vip: false,
    };
  },
  methods: {
    onChangeMake() {
      this.makeList = CarMakes
        .filter(m => m.startsWith(this.make));
    },
    onChangeColor() {
      this.completeColorList = Object.keys(CompleteColorList)
        .filter(m => m.startsWith(this.otherColor));
    },
    scanresults(scan) {
      this.model = scan.model;
      this.make = scan.make;
      this.otherColor = scan.color;
      this.plate = scan.plate;
      if (this.plate) {
        this.validatePlate();
      }
    },
    myColor() {
      this.submitcolor = 'is-success';
      this.submittext = 'Checked in!';
      setTimeout(this.resetForm, 2000);
    },
    resetMyColor() {
      this.submitcolor = 'is-info';
      this.submittext = 'Submit';
    },
    resetForm() {
      // https://jsfiddle.net/bf1631pu/ <--- nice
      Object.assign(this.$data, this.$options.data());
    },
    processForm() {
      if (!this.isValidMobile(this.mobile)
            || !this.isValidPlate(this.plate)) {
        alert('Please enter a valid plate and mobile to continue');
      } else {
        // backup what needs to be sent
        const plate = this.plate.toUpperCase();
        const mobile = this.cc + this.mobile;
        const { make } = this;
        const { model } = this;
        const { otherColor } = this;

        this.myColor();

        // call API
        this.signupUser({
          plate,
          tn: mobile,
          // zone_id: this.zone_id,
          zone_id: this.zoneId,
          car: {
            color: otherColor,
            make,
            model,
          },
        });
        this.log = plate;
      }
    },

    validateMobile() {
      const isValid = this.isValidMobile(this.mobile);
      this.errors.mobile = !isValid;
    },
    validatePlate() {
      const isValid = this.isValidPlate(this.plate);
      this.errors.plate = !isValid;
      if (isValid) {
        this.checkVIPStatus(this.plate);
      }
    },
    checkVIPStatus(plate) {
      this.$http.get(`/users/plate/${plate}?fields=-_id+tn+car&limit=1`)
        .then((res) => {
          // console.log(res.data)

          // make sure we got something back
          if (res.data.length) {
            console.log(`VIP status: ${!!res.data[0].tn}, number: ${res.data[0].tn}`);

            // separate out the cc from the number
            for (const el of this.ccList) {
              if (res.data[0].tn.startsWith(el)) {
                this.mobile = res.data[0].tn.split(el)[1];
                this.cc = el;
                this.vip = true;
                break;
              }
            }

            // grab make/model/color if available
            if (res.data[0].car) {
              this.otherColor = res.data[0].car.color;
              this.make = res.data[0].car.make;
              this.model = res.data[0].car.model;
            }
          }
          // else reset back to default. TODO: cc shouldn't really default to +1
          else {
            this.mobile = '';
            this.cc = '+1';
            this.vip = false;
          }
        })
        .catch((e) => {
          console.log(`Error: ${e.message}`);
        });
    },
    signupUser(body) {
      this.$http.post(`/zones/${this.zoneId}/users`, body)
        .then((res) => {
          // reset form (can't use resetForm above because it executes async. Maybe turn it in to a promise?)
          // this.plate = this.mobile = this.make = this.model = this.color = this.otherColor = '';
          // this.colorList.map((x) => {
          //   x.state = false
          //   return x
          // })
        })
        .catch(e => console.log(e));
    },
    // helpers ===============================================
    /**
       * Validate email
       * Not 100% reliable. Just a quick and dirty check.
       */
    isValidEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    /**
       * Validate plate
       */
    isValidPlate(plate) {
      const re = /^[a-zA-Z\-0-9]{6,}$/;
      return re.test(plate);
    },

    /**
       * Validate phone mobile
       */
    isValidMobile(mobile) {
      const re = /^[0-9]{10}$/;
      // return re.test(mobile);
      return true; // eventually use spec to verify number, disabled for now
    },
  },
};

</script>

<style>
@import '../../node_modules/car-makes-icons/dist/style.css';

.my-menu {
  z-index: 2;
  position: absolute;
  font-weight: 400;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: black;
  min-width: 120px;
  box-shadow: 1px 2px 1px #ccc;
}

.my-hr {
  border: none;
  /* border-top: 0px solid #ccc; */
  overflow: visible;
  text-align: center;
}

.my-hr::after {
  background: #fff;
  padding: 0 10px;
  content: 'OPTIONAL';
  font-size: 0.6em;
  position: relative;
  top: -14px;
  color: #bbb;
}

.valet {
  /* max-width: 82%;
  margin-left: auto;
  margin-right: auto; */
  background: #eee;
}

.myfooter {
  font-size: small;
  text-align: center;
  padding-top: 25px;
}

input {
  display: none;
}


</style>
