<template>
<div>
<section class="section has-background-white-ter">
<div class="container">
    <div class="tabs is-centered is-toggle is-touch is-fullwidth">
        <ul>
            <li @click="isActiveTab='tab1'" :class="[ isActiveTab === 'tab1' ? 'is-active':'']">
                <a>
                    <span class="icon is-small"><i class="fas fa-check" aria-hidden="true"></i></span>
                    <span>Check-in</span>
                </a>
            </li>

            <li @click="isActiveTab='tab2'" :class="[ isActiveTab === 'tab2' ? 'is-active':'' ]">
                <a>
                    <span class="icon is-small"><i class="fas fa-arrow-right" aria-hidden="true"></i></span>
                    <span>Check-out</span>
                    <!-- <StatusIcons :counts="{in: checkinCount, sum: summonCount, arr: arriveCount, out: checkoutCount}"></StatusIcons> -->
                </a>
            </li>

            <li @click="isActiveTab='tab3'" :class="[ isActiveTab === 'tab3' ? 'is-active':'' ]">
                <a>
                    <span class="icon is-small"><i class="fas fa-cog" aria-hidden="true"></i></span>
                    <span>&#x200b;</span>
                    <!-- <span>Settings</span> -->
                </a>
            </li>

        </ul>
    </div>

    <section id="content1" v-if="isActiveTab === 'tab1'"><valet-checkin :zoneId="zone_id" :zoneName="zoneName" :valetNumber="zoneNumber" :enhCheckin="enhCheckin" :summonDisable="summonDisable"></valet-checkin></section>
    <section id="content2" v-if="isActiveTab === 'tab2'"><valet-checkout :zoneId="zone_id" :postArray="postList"></valet-checkout></section>
    <section id="content3" v-if="isActiveTab === 'tab3'"><valet-help :zoneId="zone_id" :zoneName="zoneName" :valetNumber="zoneNumber" :zoneMesg="zoneMesg" :summonDisable="summonDisable"></valet-help></section>


</div>
</section>
<section>
        <StatusFooter @status="isActiveTab=$event" :counts="{in: checkinCount, sum: summonCount, arr: arriveCount, out: checkoutCount}" :summonDisable="summonDisable"></StatusFooter>
</section>
<section v-if="notificationsEnabled">
        <ValetFab v-if=false></ValetFab>
</section>

</div>
</template>

<script>
import StatusIcons from './ValetStatusIcons';
import StatusFooter from './ValetStatus';
import ValetFab from './ValetFAB';
import ValetCheckin from './ValetCheckin';
import ValetCheckout from './ValetCheckout';
import ValetHelp from './ValetSettings';

// ... other imports ...
import axios from '../backend';

export default {
  name: 'Valet',
  components: {
    StatusIcons,
    StatusFooter,
    ValetCheckin,
    ValetCheckout,
    ValetHelp,
    ValetFab,
  },
  props: ['zoneId'],
  data() {
    return {
      checkinCount: 0,
      summonCount: 0,
      arriveCount: 0,
      checkoutCount: 0,
      zone_id: this.zoneId || '',
      postList: [],
      timeout: '',
      listPoll: '',
      zoneName: '',
      zoneNumber: '',
      zoneMesg: {},
      enhCheckin: false,
      summonDisable: true,
      isActiveTab: 'tab1',
      notificationsEnabled: false,
    };
  },
  // this seems hacky. AR: really need to fix this. It's required so that if someone tries to visit the component despite the token being active, it does nothing
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //    console.log('entered valet screen')
      //    console.log('checkinCount: ', vm.checkinCount)
      if (!vm.zone_id) {
        clearInterval(vm.listPoll);
        vm.$router.push(from.path);
      }
    });
  },
  methods: {
    loadPostList(posts) {
      // console.log(posts);
      // var result = Object.entries(posts).map(([key,value]) => ({'plate': value}));
      // this.postList = result;
      let summonCount; let arriveCount; let checkinCount; let
        checkoutCount;
      summonCount = arriveCount = checkinCount = checkoutCount = 0;
      for (let i = 0; i < posts.length; ++i) {
        switch (posts[i].state) {
          case 'has-background-warning': {
            summonCount++;
            break;
          }
          case 'has-background-success': {
            arriveCount++;
            break;
          }
          case 'has-background-grey-light': {
            checkoutCount++;
            break;
          }
          case 'checkin': {
            checkinCount++;
            break;
          }
          default: // do nothing
        }
      }
      this.summonCount = summonCount;
      this.arriveCount = arriveCount;
      this.checkinCount = checkinCount;
      this.checkoutCount = checkoutCount;
      this.postList = posts;
      // console.log(this.postList);
    },
    getCheckoutList() {
      this.$http.get(`/zones/${this.zone_id}`)
      // axios
      // .get(eZoneUsers)
      // .then(r=>console.log('result: ', JSON.stringify(r.data.history,'',2)))
        .then((r) => {
          this.zoneName = r.data.name;
          this.zoneNumber = r.data.contact.number;
          this.zoneMesg = r.data.sms;
          this.enhCheckin = r.data.enhCheckin;
          this.summonDisable = r.data.summonDisable;
          this.loadPostList(r.data.history);
        })
        .catch(e => console.log(e));
    },
    refresh() {
      // console.log('got event')
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        // Log out the user
        this.$router.replace('Login');
      }, 1000 * 60 * 60);
      // console.log(`timeout: ${this.timeout}`)
    },
    notifyme() {
      console.log('notification permissions: ', Notification.permission);

      // Let's check if the browser supports notifications
      if (!('Notification' in window)) {
        console.log('This browser does not support desktop notification');
      }

      // Let's check whether notification permissions have alredy been granted
      else if (Notification.permission === 'granted') {
        // If it's okay let's create a notification
        const notification = new Notification('Hi there!');
        this.notificationsEnabled = true;
      }

      // Otherwise, we need to ask the user for permission
      else if (Notification.permission !== 'denied' || Notification.permission === 'default') {
        Notification.requestPermission()
          .then((permission) => {
            console.log('requesting permission ...');
            // If the user accepts, let's create a notification
            if (permission === 'granted') {
              const notification = new Notification('Hi there!');
              this.notificationsEnabled = true;
            }
          });
      }
    },
  },
  // filters: {
  //     formatDate: function(value) {
  //         return moment(value).format('h:mma');
  //     },
  // },
  // computed: {
  //     filteredList() {
  //         // console.log(this.postList);

  //         return this.postList
  //         .sort((a,b) => {return (a.state > b.state)? -1 : 1 })
  //         .filter(p => {
  //             return (p.plate.toLowerCase().includes(this.search.toLowerCase()) ||
  //             p.tn.includes(this.search));
  //         })
  //     },
  // },
  created() {
    // this.notifyme();
    this.getCheckoutList();
    this.listPoll = setInterval(this.getCheckoutList, 9500);

    this.refresh();
    window.addEventListener('click', () => { this.refresh(); });
    window.addEventListener('touchstart', () => { this.refresh(); });
    window.addEventListener('keypress', () => { this.refresh(); });
    // console.log('created listener for click/touch/keypress')
  },
};


</script>

<style>

</style>
