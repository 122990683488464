<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="column is-half is-offset-one-quarter has-text-centered">
        <!-- <nav class="level">
          <div class="level-item has-text-centered">
        <figure class="image is-centered"> -->
            <!-- <img src="@/assets/INVERTED_no_bkgnd_no_box_Logo.png"> -->
            <img src="@/assets/Logo-06.png" width="225">
        <!-- </figure>
          </div>
        </nav> -->
        <!-- <h3 class="title has-text-grey">divrt GO</h3> -->
          <!-- <p class="subtitle has-text-grey">Please login to proceed</p> -->
          <!-- <div class="box"> -->
            <!-- <figure class="avatar">
              <img src="https://placehold.it/128x128">
            </figure> -->
            <form class="form-signin" @submit.prevent="login">
              <div class="field">
                <div class="control has-icons-left">
                  <input class="input is-medium" type="email" placeholder="Email" required autofocus="" v-model="email">
                  <span class="icon is-left">
                    <i class="fas fa-envelope"></i>
                  </span>
                </div>
              </div>

              <div class="field">
                <div class="control has-icons-left">
                  <input class="input is-medium" type="password" placeholder="Password" reqiured v-model="password">
                  <span class="icon is-left">
                    <i class="fas fa-lock"></i>
                  </span>
                </div>
              </div>

              <!-- <div class="field">
                <label class="checkbox">
                  <input type="checkbox">
                    Remember me
                </label>
              </div> -->

              <button class="button is-block is-link is-medium is-fullwidth" type="submit">Login</button>
            </form>
          <!-- </div> -->
          <div class="notification is-warning" v-if="error">
            <button class="delete" v-on:click="closeWarning"></button>
            <strong> {{ error }} </strong>
            {{ err_message }}
          </div>
          <!-- <p class="has-text-grey">
            <a href="../">Sign Up</a> &nbsp;·&nbsp;
            <a href="../">Forgot Password</a> &nbsp;·&nbsp;
            <a href="../">Need Help?</a>
          </p> -->
        </div>
      </div>
    </div>
    <div class="notification has-background-warning has-text-centered is-size-7" v-if="eulaShow===true">
      <button class="delete" v-on:click="[eulaShow=false]"></button>
      By logging in, you agree to our <a href="https://divrt.co/privacy"><strong> Cookie Policy, Privacy Policy, Terms and Conditions </strong></a>
    </div>

      <!-- Hero footer: will stick at the bottom -->
    <div class="hero-foot">
      <div class="container has-text-centered is-size-7">
        <p class="has-text-grey">made with
          <i class="fas fa-heart has-text-danger" aria-hidden="true"></i> by <strong>divrt</strong></p>
      </div>
  </div>
  </section>
</template>

<script>
// ... other imports ...
import axios from '../backend';

export default {
  components: {
  },
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      error: false,
      err_message: false,
      eulaShow: true,
    };
  },
  methods: {
    login() {
      // console.log({email: this.email, password: this.password})
      this.$http.post('/auth', { email: this.email, password: this.password })
        .then(res => {
          const {statusCode} = res
          console.log({statusCode})
          if (statusCode == 401 || statusCode == 403) {
            throw new Error('bad request')
          }
          this.loginSuccessful(res)
        })
        .catch(err => this.loginFailed(err));
    },

    loginSuccessful(req) {
      console.log(req.data);
      if (!req.data.token || !req.data.zone) {
        this.loginFailed();
        return;
      }

      localStorage.token = req.data.token;
      axios.defaults.headers.common.Authorization = `Bearer ${req.data.token}`;
      this.error = false;
      this.err_message = false;

      console.log('login successful. zone_id: ', req.data.zone);
      this.$router.replace({
        name: 'Valet',
        params: {
          zoneId: req.data.zone,
        },
      });
    },

    loginFailed(err) {
      this.error = 'Login failed!';
      this.err_message = err.response.data.message || 'Please try again';
      delete localStorage.token;
      delete axios.defaults.headers.common.Authorization;
    },

    closeWarning() {
      this.error = false;
    },
  },
};
</script>

<style scoped>

</style>
