// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from '@/App';
import { router } from '@/router';
import '../node_modules/bulma/css/bulma.css';

// import bulmaDivider from 'bulma-divider';

// ... other imports ...
import axios from '@/backend';

import './registerServiceWorker'

// add FA5 using library method
dom.watch();
library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router,
  axios,
  components: { App },
}).$mount('#app');

/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   router,
//   axios,
//   components: { App },
//   template: '<App/>',
// });
