<template>
    <div @click="onClick" v-if="car.state === 'checkin' || car.state === 'has-background-warning' || car.state === 'has-background-success'">
        <slot></slot>
    </div
    ><div v-else>
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'ConfirmCheck',
  props: ['car', 'zoneId'],
  data() {
    return {
      zone_id: this.zoneId,
    };
  },
  methods: {
    onClick() {
      if (confirm(this.warning())) this.sure();
    },
    warning() {
      switch (this.car.state) {
        case 'has-background-warning': {
          // summon -> arrive case
          return `Ensure car ${this.car.plate} is back. \nSend SMS to ${this.car.tn}   now?`;
          break;
        }
        case 'checkin':
        case 'has-background-success': {
          // arrive -> checkout case
          return `Confirm customer has picked up car ${this.car.plate}`;
          break;
        }
        default: // do nothing
      }
    },
    sure() {
      switch (this.car.state) {
        case 'has-background-warning': {
          // summon -> arrive case. Means car was summoned, and is now back. inform
          // the user and switch car state to arrive
          this.$http.patch(`/zones/${this.zone_id}/users/${this.car._id}`, { state: 'arrive' })
            //   axios
            //     .patch(eUsers + '/' + car._id, {"state": "arrive"})
          // .then(r=>console.log('result: ', JSON.stringify(r,'',2)))
            .catch(e => console.log(e));
          alert(`SMS sent to ${this.car.tn}`);
          break;
        }
        case 'checkin':
        case 'has-background-success': {
          // arrive -> checkout case. Means user has taken car away. check it out.
          // and switch state to checkout
          this.$http.patch(`/zones/${this.zone_id}/users/${this.car._id}`, { state: 'checkout' })
            //   axios
            //     .patch(eUsers + '/' + car._id, {"state": "checkout"})
            //     .then(r=>console.log('result: ', JSON.stringify(r,'',2)))
            .catch(e => console.log(e));
          alert(`Customer ${this.car.plate} checked out`);
          break;
        }
        default: // do nothing
      }
    },
  },
};
</script>

<style>

</style>
