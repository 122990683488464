<template>
  <div style="margin-bottom: 100px">

     <div class="field has-addons has-addons-centered searchbar">

      <div class="control has-icons-left">
        <input class="input is-medium" type="text" placeholder="Plate or Mobile ... " v-model="search">
        <span class="icon is-left">
          <i class="fas fa-search"/>
        </span>
      </div>
    </div>

    <div class="content has-text-centered">
    <label class="checkbox">
        <input style="display:inline" type="checkbox" v-model="showCheckedOutCars">
        Show Cars Already Checked-out
    </label>
    </div>

    <div class="columns is-multiline" >
      <div class="column is-6" v-for="(post, postIndex) in filteredList" :key="postIndex">
        <confirm-check :car="post" :zoneId="zone_id">
          <article class="box" style="border: 1px solid lightgrey" v-bind:class="post.state">
            <div v-if="post.car && post.car.color" style="padding-bottom: 10px">
              <span class="is-uppercase is-small tag is-black has-text-weight-semibold"> {{ post.car.color }} / {{ post.car.make }} {{ post.car.model }} </span>
            </div>
            <plate :plate="post.plate"/>
            <div class="stamp" v-if="post.payment && post.payment.status">{{post.payment.status}}</div>
            <p class="subtitle">{{ post.tn }}</p>
            <!-- AR mental note: Google indicates an aync load on a nested json is the reason for this not loading normally. Weird, because the subobject does load. :gofigure: -->
            <p>
              <span class="tag is-white">{{ post.state_times && post.state_times.checkin | formatDate }}</span>
              <span class="tag" style="background-color: #FFCa33" v-if="post.state_times.summon">{{ post.state_times.summon | formatDate }}</span>
              <span class="tag" style="background-color: #00c04f" v-if="post.state_times.arrive">{{ post.state_times.arrive | formatDate }}</span>
              <span class="tag" style="background-color: #dfdfdf" v-if="post.state_times.checkout">{{ post.state_times.checkout | formatDate }}</span>
            </p>
            <p class="tag is-rounded is-size-7" v-bind:class="post.state">
                {{ post.state_times && post.state_times.checkin | getDay }}
            </p>
          </article>
        </confirm-check>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
import dayjs from 'dayjs';
import ConfirmCheck from './ValetModal';
import Plate from './Plate';

export default {
  name: 'ValetCheckout',
  props: ['zoneId', 'postArray'],
  components: {
    ConfirmCheck,
    Plate,
  },
  data() {
    return {
      search: '',
      zone_id: this.zoneId,
      showCheckedOutCars: false,
    };
  },
  methods: {
  },
  filters: {
    formatDate(value) {
      // return moment(value).format('h:mma');
      return dayjs(value).format('h:mma');
    },
    getDay(value) {
      // return moment(value).format('dddd, MMMM Do');
      // return moment(value).calendar();
      // return moment(value).format('ll');
      return dayjs(value).format('MMM DD');
    },
  },
  computed: {
    filteredList() {
      // console.log(JSON.stringify(this.postArray[0]));

      return this.postArray
        .filter(p => (this.showCheckedOutCars ? p : !p.state.includes('has-background-grey-light')))
        .sort((a, b) => (((a.state > b.state)
                    || (a.state_times.checkin > b.state_times.checkin)) ? -1 : 1))
        .filter(p => (p.plate.toLowerCase().includes(this.search.toLowerCase())
                || p.tn.includes(this.search)));
    },
  },
};
</script>

<style>
    p {
        font-weight: 400;
    }
    /* .content.has-text-centered {
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
    } */
    .box {
        margin-top: 0rem;
        position: relative;
    }
    .searchbar {
        padding-bottom: 15px;
    }

    article:hover {
        cursor: pointer;
    }

    article.checkout, article.has-background-grey-light {
        cursor:not-allowed;
    }

    .stamp {
        transform: rotate(12deg);
        color: black;
        font-size: 1.75rem;
        font-weight: 600;
        border: 0.25rem solid black;
        display: inline-block;
        padding: 0rem 0.5rem;
        text-transform: uppercase;
        border-radius: 0.75rem;
        font-family: 'Courier';
        -webkit-mask-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png');
        -webkit-mask-size: 944px 604px;
        mix-blend-mode: multiply;
        position: absolute;
        right: 8px;
      }
</style>
