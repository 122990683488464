<template>
    <div style="margin-left: 10px">
        <span class="icon is-size-5-touch fa-border" v-if="counts.in">
            {{ counts.in }}
        </span>
        <span class="icon is-size-5-touch has-background-warning" v-if="counts.sum">
            {{ counts.sum }}
        </span>
        <span class="icon is-size-5-touch has-background-success" v-if="counts.arr">
            {{ counts.arr }}
        </span>
        <span class="icon is-size-5-touch has-background-grey-lighter fa-border" v-if="counts.out">
            {{ counts.out }}
        </span>
    </div>
</template>

<script>
export default {
  name: 'StatusIcons',
  props: ['counts'],
  data() {
    return {
    };
  },
};
</script>

<style>

</style>
