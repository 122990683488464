<template>
    <div class="plate-box">
        <div id="plate-frame">
        <p class="title">&middot;{{plate}}&middot;</p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Plate',
  props: ['plate'],
};
</script>

<style>

@font-face {
    font-family: LicensePlate;
    src: url("../assets/LicensePlate.ttf") format("truetype");
    /* font-display: optional; */
}

div.plate-box {
    padding-bottom: 0.5em;
    display: inline-block;
}

#plate-frame {
    /* font-family: LicensePlate, Monaco; */
    background-color: white;
    border:2px #555 solid;
    border-radius: 7px;
    padding: 0.5em 0 0 0;
}

#plate-frame p {
    font-family: LicensePlate, Impact;
    font-size: 2.5em;
    font-weight: 500;
}

</style>
