<template>
    <div class='container'>
    <span class="fix-fab content is-large">
      <i class="fas fa-exclamation-triangle"></i>
    </span>

    </div>
</template>

<script>
export default {
  name: 'ValetFAB',
  data() {
    return {
    };
  },
  methods: {

  },
};

</script>

<style>

.fix-fab {
  position: fixed;
  left: 10%;
  bottom: 15%;
  background-color: #FF7900;
  padding: 10px;
  width: 60px;
  height: 60px;
  color:#FFF;
  border: none;
  border-radius:50%;
  text-align:center;
  box-shadow: 0 0 0 0 rgba(255,121,0, 0.5);
  /* transition: all 0.1s ease-in-out; */
  -webkit-animation: pulse 1.5s infinite;
  animation: pulse 1.5s ease-in-out 0s infinite normal;
}

@keyframes pulse {
  0% {
    transform: scale(.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(255,121,0, 0);
  }
  100% {
    transform: scale(.9);
    box-shadow: 0 0 0 0 rgba(255,121,0, 0);
  }
}

@-webkit-keyframes pulse {
  0%  { transform: scale(.9)}
  70% {
    transform: scale(1.0);
    box-shadow: 0 0 0 200px rgba(255,121,0, 0);
  }
  100% {
    transform: scale(.9);
    box-shadow: 0 0 0 0 rgba(255,121,0, 0);
  }
}

.fix-fab:hover {
  /* box-shadow: 2px 2px 5px #666;
  transform: scale(1.03); */
  -webkit-animation: none;
  animation: none;
}

/* .heading {
  font-weight: 400;
} */
</style>
