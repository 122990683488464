<template>
    <div class="fix-footer">
      <div class="content">
        <nav class="level is-centered is-mobile">
          <div @click="$emit('status', 'tab1')" class="level-item has-text-centered">
            <div>
              <p class="heading">Checked In</p>
              <p class="title">{{ counts.in }}</p>
            </div>
          </div>

          <div v-if="!summonDisable" @click="$emit('status', 'tab2')" class="level-item has-text-centered" :class="warnSummon()">
            <div>
              <p class="heading">Summoned</p>

              <p class="title">{{ counts.sum }}</p>

              <!-- <i class="fas fa-spinner fa-pulse"></i> -->
            </div>
          </div>

          <div v-if="!summonDisable" @click="$emit('status', 'tab2')" class="level-item has-text-centered" >
            <div>
              <p class="heading">Arrived</p>
              <p class="title">{{ counts.arr }}</p>
            </div>
          </div>

          <div @click="$emit('status', 'tab2')" class="level-item has-text-centered">
            <div>
              <p class="heading">Checked Out</p>
              <p class="title">{{ counts.out }}</p>
            </div>
          </div>
        </nav>
      </div>
    </div>
</template>

<script>
export default {
  name: 'ValetStatus',
  props: ['counts', 'summonDisable'],
  data() {
    return {
    };
  },
  methods: {
    warnSummon() {
      // console.log('summon count is: ', this.counts.sum)
      return (this.counts.sum > 0) ? 'pulsed' : '';
    },
  },
};
</script>

<style>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fix-footer {
    position: fixed;
    border-top: 1px solid #ddd;
    /* left: 15%; */
    bottom: 0;
    /* overflow:hidden; */
    background-color: #fff;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    /* height: 100px; */
}

.pulsed {
    background-color: hsl(48, 100%, 67%);
    border: none;
	  border-radius:0%;
	  box-shadow: 0 0 0 0 hsl(48, 100%, 67%, 0.9);
    /* transition: all 0.1s ease-in-out; */
    -webkit-animation: pulse 1.5s infinite;
    animation: pulsing 1.5s ease-in-out 0s infinite normal;
}

@keyframes pulsing {
  /* 0% {
    transform: scale(1.0);
  } */
  70% {
    box-shadow: 0 0 0 30px hsl(48, 100%, 67%, 0);
  }
  100% {
    box-shadow: 0 0 0 0 hsl(48, 100%, 67%, 0);
  }
}

@-webkit-keyframes pulsing {
  /* 0%  { transform: scale(1.0)} */
  70% {
    box-shadow: 0 0 0 30px hsl(48, 100%, 67%, 0);
  }
    100% {
    box-shadow: 0 0 0 0 hsl(48, 100%, 67%, 0);
  }
}

.heading {
    font-weight: 400;
}
</style>
