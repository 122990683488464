<template>
  <div id="app">
    <div v-if="env === 'development'">
      <p class="is-size-7 has-text-white has-background-primary">
        app: <strong> {{ title }} </strong>
        version: <strong> {{ version }} </strong>
        build: <strong> {{ env }} </strong>
      </p>
    </div>
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>-->
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      env: process.env.NODE_ENV,
      title: process.env.VUE_APP_TITLE || "",
      version: process.env.VUE_APP_VERSION
    };
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;
  color: #2c3e50; */
}

/* #nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
