<template>
    <div class="content" style="margin-bottom: 100px">
        <div class="box" style="border: 1px solid lightgrey">

            <h3>Settings for {{zoneName}}</h3>

            <!-- mobile number changes -->
            <div v-if="!summonDisable">
            <nav style="margin-bottom:10px" class="level is-mobile">
                <div class="level-left">
                    <div class="level-item">
                        <label class="label">Supervisor Mobile Number</label>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <div class="field">
                            <input v-model="smsNumber.toggle" v-on:change="changeSettings(smsNumber)" id="switchExample" type="checkbox" name="switchExample" class="switch is-link is-rtl is-small">
                            <label for="switchExample"></label>
                        </div>
                    </div>
                </div>
            </nav>
            <div class="field has-addons">
                <div class="control has-icons-left">
                    <input class="input" type="text" :disabled="!smsNumber.toggle" v-model="smsNumber.text" v-on:change="[smsNumber.dirty = true]">
                    <span class="icon is-left">
                        <i class="fas fa-phone"></i>
                    </span>
                    <p style="margin-bottom:10px" :class="smsNumber.help">{{smsNumber.helptext}}</p>
                </div>
            </div>
            </div>

            <!-- check-in SMS changes -->
            <nav style="margin-bottom:10px" class="level is-mobile">
                <div class="level-left">
                    <div class="level-item">
                        <label class="label">Check-in SMS</label>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <div class="field">
                            <input v-model="checkinSms.toggle" v-on:change="changeSettings(checkinSms)" id="switchExample2" type="checkbox" name="switchExample2" class="switch is-link is-rtl is-small">
                            <label for="switchExample2"></label>
                        </div>
                    </div>
                </div>
            </nav>
            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field has-addons">
                        <div class="control is-expanded">
                            <textarea class="textarea" rows="3" type="text" v-model="checkinSms.text" :disabled="!checkinSms.toggle" v-on:change="[checkinSms.dirty=true]"></textarea>
                            <p :class="checkinSms.help">{{checkinSms.helptext}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- arrive SMS changes -->
            <div v-if="!summonDisable">
            <nav style="margin-bottom:10px" class="level is-mobile">
                <div class="level-left">
                    <div class="level-item">
                        <label class="label">Arrived SMS</label>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <div class="field">
                            <input v-model="arriveSms.toggle" v-on:change="changeSettings(arriveSms)" id="switchExample3" type="checkbox" name="switchExample3" class="switch is-link is-rtl is-small">
                            <label for="switchExample3"></label>
                        </div>
                    </div>
                </div>
            </nav>
            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field has-addons">
                        <div class="control is-expanded">
                            <textarea class="textarea" rows="2" type="text" v-model="arriveSms.text" :disabled="!arriveSms.toggle" v-on:change="[arriveSms.dirty=true]"></textarea>
                            <p style="margin-bottom:10px" :class="arriveSms.help">{{arriveSms.helptext}}</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <!-- checkout SMS changes -->
            <nav style="margin-bottom:10px" class="level is-mobile">
                <div class="level-left">
                    <div class="level-item">
                        <label class="label">Checkout SMS</label>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <div class="field">
                            <input v-model="checkoutSms.toggle" v-on:change="changeSettings(checkoutSms)" id="switchExample4" type="checkbox" name="switchExample4" class="switch is-link is-rtl is-small">
                            <label for="switchExample4"></label>
                        </div>
                    </div>
                </div>
            </nav>
            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field has-addons">
                        <div class="control is-expanded">
                            <textarea class="textarea" rows="4" type="text" v-model="checkoutSms.text" :disabled="!checkoutSms.toggle" v-on:change="[checkoutSms.dirty=true]"></textarea>
                            <p :class="checkoutSms.help">{{checkoutSms.helptext}}</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <h3>Need help? Please contact <a href="mailto:support@divrt.co">support@divrt.co</a></h3>

        <!-- <footer class="footer logoutfooter"> -->
        <div class="field has-text-right" style="padding-top:20px">
            <button v-on:click="logout()" class="button is-large is-fullwidth has-text-white has-background-link" type="submit">Logout</button>
        </div>
        <!-- </footer> -->
    </div>
</template>

<script>
import axios from '../backend';
import bulmaSwitch from 'bulma-switch';

export default {
  name: 'ValetHelp',
  props: [
    'valetNumber',
    'zoneId',
    'zoneName',
    'zoneMesg',
    'summonDisable',
  ],
  data() {
    return {
      valetSmsNumber: this.valetNumber || '',
      smsNumber: {
        text: this.valetNumber || '',
        toggle: false,
        dirty: false,
        entry: 'contact.number',
        help: 'help',
        helptext: 'Summon requests will go here',
      },
      checkinSms: {
        text: '',
        toggle: false,
        dirty: false,
        entry: 'sms.checkin',
        helptext: 'Message sent to visitor after checkin',
        help: 'help',
      },
      arriveSms: {
        text: '',
        toggle: false,
        dirty: false,
        entry: 'sms.arrive',
        help: 'help',
        helptext: 'Message sent to visitor when car is ready for pickup',
      },
      checkoutSms: {
        text: '',
        toggle: false,
        dirty: false,
        entry: 'sms.checkout',
        help: 'help',
        helptext: 'Message sent to visitor after checkout',
      },
    };
  },
  methods: {
    logout() {
      this.$router.replace('Login');
    },
    getDefaultSmsText() {
      axios
        .get('messages')
        .then((r) => {
          this.checkinSms.text = this.zoneMesg.checkin || r.data[0].checkin;
          this.arriveSms.text = this.zoneMesg.arrive || r.data[0].arrive;
          this.checkoutSms.text = this.zoneMesg.checkout || r.data[0].checkout;
        });

      // Promise.all([
      //     axios.get('/messages'),
      //     axios.get('/zones/' + this.zoneId + '/?f=sms')
      //     ])
      //     .then(([orig, override]) => {
      //         // console.log(orig.data[0].checkin)
      //         // console.log(override.data.sms.checkin)
      //         this.checkinSmsText = override.data.sms.checkin || orig.data[0].checkin
      //     });
    },
    changeSettings(field) {
      if (!field.toggle && field.dirty) {
        const updatedRecord = { [field.entry]: field.text };
        // console.log(updatedRecord)
        axios
          .patch(`/zones/${this.zoneId}`, updatedRecord)
          .then((r) => {
            console.log('updated SMS number');
            field.dirty = false;
            field.helptext = 'Update successful';
            field.help = 'help is-danger';
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
  created() {
    this.getDefaultSmsText();
  },
  computed: {

  },
};
</script>

<style scoped>

h3 {
    text-align: center;
}

</style>
